<template>
    <div class="ma-0 "> 
           

        <!-- //---------------------DIALOG -->
        <div>  
            <!-- RECORD EXPENSE -->
            <v-dialog
                v-model="dialog_record_expenses"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_record_expenses"> 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">RECORD EXPENSE</div> 
                        <v-spacer></v-spacer> 
                         <v-btn @click="dialog_record_expenses=!dialog_record_expenses" icon dark><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <!-- <div class="mb-2">Order details  </div> -->
                        <form @submit.prevent="CREATE_NEW_EXPENSE(input,cashup)"> 
                            <mbs-input-fields class="mx-4 my-3"
                                v-model="input"
                                :outlined="true"
                                :dense="false"
                                py="1" px="1"
                                :field_items="DATA.FIELDS.expenses"
                                :select_items="DataSource"  
                                />  
                            <v-layout row wrap class="ma-0 " >
                                <v-btn @click="dialog_record_expenses = false" large  
                                    :loading="inputLoading"
                                    class="px-9 mt-3 secondary"  color="primary" height="60"  >
                                    <div class="not-f5">Cancel</div>  
                                </v-btn>   
                                <v-flex >
                                    <v-btn type="submit" large  
                                        :loading="inputLoading"
                                        class="mt-3 ml-1"  color="primary" height="60" width="100%">
                                        <div class="not-f5">Save Expense</div>  
                                    </v-btn>   
                                </v-flex> 
                                <!-- <v-flex sm4> 
                                    <v-menu v-if="MBS.actions.SIZE(ItemOrderOptionsData)>0"
                                        v-model="menu_send_and_print_option" 
                                        :close-on-content-click="false" 
                                        offset-y  offset-overflow
                                        :nudge-width="90" rounded="xl">

                                        <template v-slot:activator="{ on }">   
                                             
                                            <v-btn large  v-show="false" type="submit"
                                                :loading="inputLoading" ref="ref_btn_send_and_print"
                                                class="mt-3 "  color="secondary" height="60" width="99%">
                                                <div class="not-f5">Send & Print</div>  
                                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                            </v-btn> 
                                            <v-btn large  v-on="on"
                                                :loading="inputLoading" 
                                                class="mt-3 "  color="secondary" height="60" width="99%">
                                                <div class="not-f5">Send & Print</div>  
                                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                            </v-btn> 
                                        </template>  
                                        <v-card>
                                            <v-card-title primary-title class="secondary white--text">
                                                Select Print Option
                                            </v-card-title>
                                            <v-list>
                                                <v-list-item type="submit"  @click="SEND_AND_PRINT_NEW_ORDER('print_docket_and_bill')">
                                                    Send & Print Both Docket & Bill
                                                </v-list-item> 
                                                <v-list-item type="submit" @click="SEND_AND_PRINT_NEW_ORDER('print_docket')">
                                                    Send & Print Docket
                                                </v-list-item>
                                                <v-list-item type="submit" @click="SEND_AND_PRINT_NEW_ORDER('print_bill')">
                                                    Send & Print Bill
                                                </v-list-item> 
                                            </v-list>
                                        </v-card>
                                    </v-menu> 
                                </v-flex>  -->
                            </v-layout>  
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog> 
            
            <!-- VIEW EXPENSES -->
            <v-dialog
                v-model="dialog_view_expenses"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="1100px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_view_expenses"> 
                    <!-- <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">RECORD EXPENSE</div> 
                        <v-spacer></v-spacer> 
                         <v-btn @click="dialog_view_expenses=!dialog_view_expenses" icon dark><v-icon>close</v-icon></v-btn>
                    </v-card-title>  -->
                    <mbs-item-manager
                        :filters="FILTERS" 
                        :add_vertical_lines="true"
                        :row_click="true"
                        :row_click_action="false" 
                        :height="vsh-480"
                        :min_height="300"
                        :table_title="'Recorded Expenses'"
                        :table_title_icon="'mdi-clipboard-text-clock'"
                        :top_label="false"
                        :filter_date="true"
                        :filter_date_value="'expense_date'"
                        :filter_default_days="'90'"
                        :name_items="DATA.ITEMS.EXPENSES.names"
                        :name_item="DATA.ITEMS.EXPENSES.name"
                        :value_items="DATA.ITEMS.EXPENSES.values"
                        :value_item="DATA.ITEMS.EXPENSES.value"
                        :field_items="DATA.FIELDS.expenses"
                        :header_items="HEADER_ITEMS" 
                        :data_items="ExpensesData"      
                        :is_joined="true"  
                        :path_item="PATH_ITEM"  
                        :select_items="DataSource"  
                        :total_item_values="TOTAL_ITEM_VALUES"  

                        :table_actions="table_actions" 
                        @SELECT_TABLE_ACTION="TABLE_ACTION"
                        @SELECT_ITEM_ACTION="ITEM_ACTION"
                    />
                </v-card> 
            </v-dialog>  
          

            <!-- //print -->
            <div v-if="auto_print.printing"> 
                <mbs-auto-print 
                    @finish_print="FINISH_PRINT"
                    :show="true"  
                    :auto_print="auto_print.auto"
                    :auto_printer_name="auto_print.auto_printer_name"
                    :com_printer="auto_print.com_printer"  
                    :data="auto_print.print_data"
                    :type="auto_print.print_type"
                    :title="auto_print.print_title"
                    :printing_action="auto_print.printing_action"
                    :display_option="auto_print.display_option" 
                    />  
            </div> 
        </div>
    </div>
</template> 
<script>
let PAGE_NAME = 'ORDER-OPTION'
import DATA from "../../plugins/DATA"
import DB from "../../plugins/DB"
import {mapState } from "vuex" 
export default { 
    props:['cashup',''],
    data(){
        return{  
            body_height:0,
             
            input:{}, 
            inputLoading:false,  
            DATA:DATA,   
            dialog_record_expenses:false,   
            dialog_view_expenses:false,   

            auto_print:{
                auto:false,
                printing:false, 
                print_data:null,
                print_type:null,
                com_printer:false, 
                print_title:null, 
                auto_printer_name:null,
                printing_action:null, 
                display_option:'',  
            },
            table_actions:[  
                {type:"action",btn:true,icon:"mdi-close",action:"close",color:"secondary", outlined:false,  text:"Close"},  
                {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
            ],
            TOTAL_ITEM_VALUES:[ 
                // {name:'Total Items',value:'total_quantity'}, 
                {name:'Total Amount',value:'amount',show_value:'amount_',money:true},
            ], 
            
        } 
    }, 
    created() {
        try {
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
            this.MBS.events.$on('ON_RECODE_EXPENSE', this.ON_RECODE_EXPENSE);   
            this.MBS.events.$on('ON_VIEW_EXPENSES', this.ON_VIEW_EXPENSES);   
 
            
        } catch (error) {
            this.MBS.actions.error({
                error:error,
                from:'created',
                page:PAGE_NAME, 
            }) 
        }
    },
    mounted(){  
    },
    computed:{
        route(){
            return this.$route
        },
        vs(){

            return this.$vuetify.breakpoint
        }, 
        vsh(){
            return this.vs.height
        },  
        ...mapState({
            loading:state=>state.load.loading,
            processes:state=>state.load.processes,
            responses:state=>state.load.responses,
        }),
        ...mapState({
            us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
            ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'], 
        }),
        ...mapState({
            CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()],  
            LocalCashups: state=> state.locals[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()],
            StockOutlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
            JoinedStockOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
            Expenses: state=> state.items[(DATA.ITEMS.EXPENSES.values).toUpperCase()],
            JoinedExpenses: state=> state.join[(DATA.ITEMS.EXPENSES.values).toUpperCase()], 

            Customers: state=> state.items[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
            JoinedCustomers: state=> state.join[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
            CustomerGroups: state=> state.items[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()], 
            JoinedCustomerGroups: state=> state.join[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()], 
            Suppliers: state=> state.items[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()], 
            JoinedSuppliers: state=> state.join[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()], 
            SupplierCategories: state=> state.items[(DATA.ITEMS.SUPPLIER_CATEGORIES.values).toUpperCase()], 
            JoinedSupplierCategories: state=> state.join[(DATA.ITEMS.SUPPLIER_CATEGORIES.values).toUpperCase()], 

            PaymentMethods: state=> state.items[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
            JoinedPaymentMethods: state=> state.join[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
            DefaultPaymentMethod: state=> state.app[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()],
            DefaultMoneyAccount: state=> state.app[(DATA.ITEMS.DEFAULT_MONEY_ACCOUNTS.values).toUpperCase()], 
            DefaultBillAccount: state=> state.app[(DATA.ITEMS.DEFAULT_BILL_METHODS.values).toUpperCase()], 
            DefaultExpenseCategories: state=> state.app['DEFAULT_EXPENSE_CATEGORIES'],
            DefaultExpenseAccounts: state=> state.app['DEFAULT_EXPENSE_ACCOUNTS'],    
        }), 
        
        ExpensesData(){
            let items = this.JoinedExpenses?this.JoinedExpenses:this.Expenses 
            if(!items){return null}
            let list = [...items]
            list.sort(this.MBS.actions.dynamicSort("created_at_server",false))   
            list.sort(this.MBS.actions.dynamicSort("expense_date",false))    
            return list 
        },
        CustomersData(){
            const Customers = this.Customers
            const JoinedCustomers = this.JoinedCustomers  
            return JoinedCustomers?JoinedCustomers:Customers
        }, 
        CustomerGroupsData(){
            const CustomerGroups = this.CustomerGroups
            const JoinedCustomerGroups = this.JoinedCustomerGroups  
            return JoinedCustomerGroups?JoinedCustomerGroups:CustomerGroups
        },
        CustomerGroupsData(){
            const CustomerGroups = this.CustomerGroups
            const JoinedCustomerGroups = this.JoinedCustomerGroups  
            return JoinedCustomerGroups?JoinedCustomerGroups:CustomerGroups
        },
        StockOutletsData(){
            let items = this.JoinedStockOutlets?this.JoinedStockOutlets:this.StockOutlets  
            return items 
        },
        ExpenseAccountsData(){ 
            let items = this.JoinedExpenseAccounts?this.JoinedExpenseAccounts:this.ExpenseAccounts
            return items
        },
        SuppliersData(){
            let items = this.JoinedSuppliers?this.JoinedSuppliers:this.Suppliers
            return items
        }, 
        PaymentMethodsData(){
            let items = []
            let DefaultBillAccount = this.DefaultBillAccount  
            let DefaultPaymentMethod = this.DefaultPaymentMethod 
            let PaymentMethods= this.JoinedPaymentMethods?this.JoinedPaymentMethods:this.PaymentMethods
            if(DefaultBillAccount){
                items = [...DefaultBillAccount]
            }
            if(DefaultPaymentMethod){
                items= [
                    ...items,
                    ...DefaultPaymentMethod
                ]
            } 
            if(PaymentMethods){
                items= [
                    ...items,
                    ...PaymentMethods
                ]
            } 
            return items
        },
        AllExpenseAccounts(){
            let defaults = this.DefaultExpenseAccounts
            defaults = this.MBS.actions.JOIN_EXPENSE_ACCOUNTS(defaults)
            let accounts = this.ExpenseAccountsData
            let all = []
            if(defaults){
                defaults.forEach(category => {
                    all.push({
                        ...category,
                        default:true
                    }) 
                });
            }
            if(accounts){
                all=[
                    ...all,
                    ...accounts
                ]
            } 
            
            return all
        },
        AllExpenseCategories(){
            let defaults = this.DefaultExpenseCategories
            let categories = this.ExpenseCategoriesData
            let all = []
            if(defaults){
                defaults.forEach(category => {
                    all.push({
                        ...category,
                        default:true
                    }) 
                });
            }
            if(categories){
                all=[
                    ...all,
                    ...categories
                ]
            } 
            return all
        },
         
        DataSource(){
            let ExpenseCategories = this.AllExpenseCategories  
            let ExpenseAccounts = this.AllExpenseAccounts  
            let Suppliers = this.SuppliersData
            let PaymentMethods = this.PaymentMethodsData   
            let StockOutlets = this.StockOutletsData   

            console.log(Suppliers,'Suppliers+++++++++++++++++++');
            
            return {
                ExpenseAccounts:ExpenseAccounts,
                Suppliers:Suppliers,
                PaymentMethods:PaymentMethods,
                ExpenseCategories:ExpenseCategories,
                StockOutlets:StockOutlets,
            }
        },

        PATH_ITEM(){  
            let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.expenses,true) 
            return path
        }, 
        FILTERS(){ 
            return [   
                {
                    type:"autocomplete",value:"payment_method_code",prepend_inner_icon:'mdi-account-cash',clearable:true,filled:false,multiple:true,
                    chips:true,deletable_chips:true,label:"Payment",
                    select:{
                        name:'PaymentMethods',
                        value:'code',
                        text:'name'
                    }, 
                    required:false,sm:12,md:6,step_name:"Name"
                },   
                {
                    type:"autocomplete",value:"expense_account_code",barcode:true,prepend_inner_icon:'mdi-cash-sync',clearable:true,filled:false,multiple:true,
                    chips:true,deletable_chips:true,label:"Account",
                    select:{
                        name:'ExpenseAccounts',
                        value:'code',
                        text:'name'
                    }, 
                    required:false,sm:12,md:6,step_name:"Name"
                },     
                {
                    type:"autocomplete",value:"supplier_code",barcode:true,prepend_inner_icon:'mdi-human-dolly',clearable:true,filled:false,multiple:true,
                    chips:true,deletable_chips:true,label:"Supplier",
                    select:{
                        name:'Suppliers',
                        value:'code',
                        text:'name'
                    }, 
                    required:false,sm:12,md:12,step_name:"Name"
                },     
            ]
        }, 
        HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"code",align:"center",show:false},     
                    {id:0,name:"Exp. date",value:'expense_date',show:true},   
                    {id:0,name:"Supplier",value:"supplier_name",show:true},     
                    {id:0,name:"Account",value:"expense_account_name",show:true},     
                    {id:0,name:"Payment",value:"payment_method_name",align:'center',show:true},     
                    {id:0,name:"Status",value:"payment_status",show:false},     
                    {id:0,name:"description",value:"description",show:true},         
                    {id:0,name:"amount",value:"amount",align:"right",show:true},     
                    {id:0,name:"uploaded at",value:'created_at_',show:false},     
                    {id:0,name:"update at",value:'updated_at_',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Edit Expense Account",action:"edit_item",icon:"edit"}, 
                        {name:"Archive Expense Account",action:"archive_item",icon:"mdi-delete"}, 
                    ]}, 
                ]
            }, 
         
         
    },
    methods:{  
        TABLE_ACTION(action,item){
            try { 
                let route = this.route
                let fullPath = route?route.fullPath:null
                    console.log(action,'action');
                if (action == "refresh") { 
                    this.REFRESH_ORDERS()
                }else if (action == "order_list") { 
                    this.CHANGE_ORDER_LIST_VIEW('list')
                }else if (action == "assistant_orders") { 
                    this.CHANGE_ORDER_LIST_VIEW('assistant')
                }else if (action == "customer_orders") { 
                    this.CHANGE_ORDER_LIST_VIEW('customer')
                }else if (action == "table_orders") { 
                    this.CHANGE_ORDER_LIST_VIEW('table')
                }else if (action == "waiter_orders") { 
                    this.CHANGE_ORDER_LIST_VIEW('waiter')
                }else if (action == "delete_all_orders") { 
                    this.CONFIRM_DELETE_ALL_ITEM_ORDER()
                }else if (action == "exit") { 
                    this.EXIT_DIALOG()
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'TABLE_ACTION',
                    page:PAGE_NAME, 
                }) 
            } 
        }, 
        ITEM_ACTION(action,item){
            try { 
                let link = "/stock/stock-control/"+(item?item.key:null)
                const path = this.MBS.actions.COMPANY_LINK(link,true) 
                console.log(item,'...');
                if (action == "view_quotation") {
                    // let account_type = item?item.account_type:null
                    // let receipt_key = item?item.key:null
                    // this.MBS.actions.go("/sales-quotation/"+receipt_key) 
                    this.SHOW_DIALOG_DOCUMENT({
                        data:item,
                        doc_title:"QUOTATION",
                        doc_type:'quotation',
                    }) 
                } else if (action == "on_row_clicked") {
                    setTimeout(() => {
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:DATA.ITEMS.EXPENSES.name,
                        action:"edit_item",
                        item:item
                    })  
                }, 50);
                }
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'TABLE_ACTION',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        ON_RECODE_EXPENSE(event){
            try { 
                const cashup = this.cashup
                const outlet_code = cashup?.outlet_code
                const cashup_key = cashup?.key
                const company_key = cashup?.company_key
                this.dialog_record_expenses = true  
                this.input = {
                    outlet_code,
                    cashup_key,
                    company_key
                }
                console.log(this.input,'this.input....ooo.');
                
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_RECODE_EXPENSE',
                    page:PAGE_NAME, 
                }) 
            } 
        }, 
        ON_VIEW_EXPENSES(event){
            try { 
                this.dialog_view_expenses = true
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_VIEW_EXPENSES',
                    page:PAGE_NAME, 
                }) 
            } 
        }, 
        CREATE_NEW_EXPENSE(input,cashup){
            try { 
                this.MBS.actions.progressDialog({show:true,title:"Adding..."})
                delete input.errors
                const moment = this.MBS.date.moment
                const update_key = moment?moment().valueOf():null 
                const expense = {
                    ...input,
                    key:update_key,
                    local_created_at:update_key,
                }
                DB.addItems({
                    name:DATA.ITEMS.LOCAL_EXPENSES.values,
                    data:expense,
                    update_locals:true
                }).then(res=>{
                    this.dialog_record_expenses = false  
                    this.MBS.actions.progressDialog()
                    const mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.EXPENSES.name,"Adding")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    })
                }).catch(error=>{
                    console.log(error,'Error.......'); 
                    this.MBS.actions.progressDialog()
                    let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.EXPENSES.name,"Adding")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo, 
                    })
                })

                console.log(input,'ll,.....');
                console.log(cashup,'cashup,.....');
                
                 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'TABLE_ACTION',
                    page:PAGE_NAME, 
                }) 
            } 
        }, 
         
        //-------------[main]
        async DIALOG_YES(action){ 
            try {
                if (action.code) {
                    if (action.code ===PAGE_NAME+'=CONFIRM-SELECT-COMPANY') {
                        if (action.YES) {
                            setTimeout(() => { 
                                this.input.check = true 
                                this.MBS.actions.dialogInput({
                                    show:true,
                                    fixed:true,
                                    title:"CONFIRM",
                                    text:"Enter your password to confirm switching to "+this.MBS.actions.TEXT_UP(action.data.company_name)+".",
                                    btnYes:"CONFIRM",
                                    field:[
                                        {field:"password",label:'Password',type:'password'}
                                    ],
                                    action:{
                                        ...action,
                                        code:PAGE_NAME+"=SELECT-COMPANY",
                                    }
                                })  
                            }, this.MBS.data.ACTION_REFRESH_TIME); 
                        } else {
                            
                        }  
                    } 
                    if (action.code ===PAGE_NAME+'=SELECT-COMPANY') {
                        if (action.YES) {
                            setTimeout(() => { 
                                let password = action.password
                                password = password?this.MBS.crypt.encrypt(password):null 
                                if (!password) { 
                                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                }else if (password!==this.ud.p_uid) { 
                                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                } else {
                                    this.SELECT_COMPANY(action.data) 
                                }   
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else {
                            
                        }
                    }   

                      
                    if (action.code ===PAGE_NAME+'=DELETE-ITEM-ORDER') {
                        if (action.YES) {
                            setTimeout(() => {  
                                this.DELETE_ITEM_ORDER(action.data)
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                        }     
                    } 
                    if (action.code ===PAGE_NAME+'=DELETE-MULTIPLE-ITEM-ORDERS') {
                        if (action.YES) {
                            setTimeout(() => {  
                                this.DELETE_MULTIPLE_ITEM_ORDERS(action.data)
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                        }     
                    }   
                    if (action.code ===PAGE_NAME+'=DELETE-ALL-ITEM-ORDERS') {
                        if (action.YES) {
                            setTimeout(() => {  
                                this.DELETE_ALL_ITEM_ORDER(action.data)
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                        }     
                    } 
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'DIALOG_YES',
                    page:PAGE_NAME, 
                }) 
            }  
        },
    },
    beforeDestroy(){
        this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);       
        this.MBS.events.$off('ON_RECODE_EXPENSE', this.ON_RECODE_EXPENSE);       
        this.MBS.events.$off('ON_VIEW_EXPENSES', this.ON_VIEW_EXPENSES);       
    },
    watch:{   
        LocalCashup(value){ 
        },
         
    }
}
</script> 